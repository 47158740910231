import Link from 'next/link';
import React, { FC, useState } from 'react'
import Icon from '~/components/Icon';
import Text from '~/components/Text';
import styles from "./LifetimeBanner.module.scss";

const LifetimeBanner:FC = () => {
    const [shouldShow, setShouldShow] = useState(true);

    function onClose() {
        setShouldShow(false);
    }

    if(!shouldShow) {
        return null;
    }

    return (
    <div className={styles.banner}>
        <div className="flex gap-[8px] items-center ml-auto">
            <div className="flex items-center justify-center py-[2px] px-[12px] bg-[#EE6E1F] rounded-[8px]">
                <Text type='title-3' color="white">Lifetime Access to CX Genie</Text>
            </div>
            <div className={styles.dot}>
                <span>&#x2022;</span>
            </div>
            <Text type='title-3' color="white">No recurring fees, one-time payment for all future updates.</Text>
            <div className={styles.dot}>
                <span>&#x2022;</span>
            </div>
            <Text type='title-3' color="white">👉</Text>
            <Link
            href={"/life-time-deals"}
            >
                <Text type='title-3' color="white" className="underline underline-offset-4">
                    Get Lifetime Deal
                </Text>
            </Link>
            
        </div>
        <Icon name="close" className={"ml-auto text-white cursor-pointer"} onClick={onClose} />
    </div>
    )
}

export default LifetimeBanner