import classNames from "classnames";
import { FC, useEffect, useState } from "react";
import Button from "~/components/Button";
import { ClashDisplayFont } from "~/pages/_app";
import styles from "./LeavePopup.module.scss";
import Image from "next/image";
import Text from "~/components/Text";
import Icon from "~/components/Icon";
import { useRouter } from "next/router";
import Link from "next/link";
import { useCookies } from "react-cookie";
import { getQueryParams } from "~/utils/common";
import { endOfDay } from "date-fns";

interface TimeLeft {
  hours: number;
  minutes: number;
  seconds: number;
}

const LeavePopup: FC = () => {
  const router = useRouter();
  const [isVisible, setIsVisible] = useState(false);
  const [timeLeft, setTimeLeft] = useState<TimeLeft>({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const { utm_campaign, utm_source } = router.query;
  const [cookie, _] = useCookies(["utm_source", "utm_blog", "others"]);
  const isBlog = router.asPath.includes("resources/b");

  const calculateTimeLeft = () => {
    const targetDate = endOfDay(new Date());

    const difference = new Date(targetDate).getTime() - new Date().getTime();
    let timeLeft: TimeLeft = {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor(difference / (1000 * 60 * 60)),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  useEffect(() => {
    const handleRouteChangeStart = (url: string) => {
      const isLifetimeLeave = sessionStorage.getItem("lifetimeLeave");
      if (router.pathname == "/life-time-deals" && !isLifetimeLeave) {
        setIsVisible(true);
        sessionStorage.setItem("lifetimeLeave", "true");
      }
      return;
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
    };
  }, [router]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={classNames(styles.section, ClashDisplayFont.variable)}>
      <div
        className={classNames(
          styles.popup,
          "bg-cover	bg-[url('/svgs/lifetime_leave_popup_bg_mobile.svg')]",
          "lg:bg-[url('/svgs/lifetime_leave_popup_bg.svg')]"
        )}
      >
        <div className="relative flex items-start justify-center w-full">
          <h2 className={styles.title}>Wait! Don’t leave just yet!</h2>
          <Button
            onClick={() => setIsVisible(false)}
            type="icon"
            className="absolute right-0 ml-auto"
          >
            <Icon name="close" color="white" />
          </Button>
        </div>
        <div className="py-[9.52px] px-[19.03px] rounded-[38.07px] bg-white">
          <Image width={121} height={28} src={"/svgs/logo.svg"} alt={"logo"} />
        </div>
        <div className={styles.text_container}>
          <p className={ClashDisplayFont.className}>
            Don’t miss your chance to get <span>lifetime access</span> to CX
            Genie’s AI-powered support tools!
          </p>
          <div className="flex flex-col gap-[6px]">
            <p className={styles.item}>One-Time Payment, No Recurring Fees</p>
            <p className={styles.item}>Unlimited Bots & Full Feature Access</p>
          </div>
          <p className={ClashDisplayFont.className}>
            Join <span>20,000+</span> businesses already boosting their
            efficiency with CX Genie
          </p>
        </div>
        <div className="flex flex-col h-[186px] items-center justify-evenly text-[white]">
          <p className={styles.act_fast}>Act fast</p>
          <div className="flex items-center gap-2">
            <div className={styles.countdown_item}>
              <p className={styles.number}>
                {timeLeft.hours.toString().padStart(2, "0")}
              </p>
              <p>hrs</p>
            </div>
            <p>:</p>
            <div className={styles.countdown_item}>
              <p className={styles.number}>
                {timeLeft.minutes.toString().padStart(2, "0")}
              </p>
              <p>min</p>
            </div>
            <p>:</p>
            <div className={styles.countdown_item}>
              <p className={styles.number}>
                {timeLeft.seconds.toString().padStart(2, "0")}
              </p>
              <p>sec</p>
            </div>
          </div>
          <Link
            type="outline"
            href={{
              pathname: `${process.env.NEXT_PUBLIC_DASHBOARD_URL}/sign-in`,
              query: {
                utm_source: isBlog
                  ? `${cookie["utm_source"] || "blog"}`
                  : `${cookie["utm_source"] || utm_source || ""}`,
                utm_campaign,
                utm_blog: isBlog ? cookie["utm_blog"] : "",
                others: cookie["others"] || getQueryParams(router.asPath),
              },
            }}
            target="_blank"
          >
            <Button variant={"white"}>Get Lifetime CX Genie Access Now</Button>
          </Link>
        </div>
        <Text className={styles.footer_item} type={"body-3"} color={"#F2F3F5"}>
          60-Day Money-Back Guarantee
        </Text>
      </div>
    </div>
  );
};

export default LeavePopup;
